import React from 'react'
import triangleBorderImg from '../../static/images/triangle-border.jpg'
import { Avatars } from '../../components'
import { useTranslation } from "react-i18next";
import { formatDate } from '../../utilities/dates'

type ServiceRequestDetailTemplateProps = {
  createdDatetime: string;
  creatorName: string;
  referenceCode: string;
  statusId: string;
  address: string;
  locationName: string;
  taskName: string;
  description: string;
  petsPresent: string;
  petsInformation: string;
  unattendedEntryPermitted: string;
  visitInstructions: string;
  reporterName: string;
  reporterEmail: string;
  reporterOrganisation: string;
  reporterTelephone: string;
  files: Array<{
    filename: string;
    contentType: string;
    url: string;
    thumbnail: string;
  }>;
  comments: Array<{
    uuid: string;
    creatorName: string;
    createdDatetime: string;
    body: string;
  }>;
  commentsIsLoading: boolean;
  informationIsLoading: boolean;
  filesIsLoading: boolean;
}

export default function ServiceRequestDetailTemplate({
  createdDatetime,
  creatorName,
  referenceCode,
  statusId,
  address,
  locationName,
  taskName,
  description,
  petsPresent,
  petsInformation,
  unattendedEntryPermitted,
  visitInstructions,
  reporterName,
  reporterEmail,
  reporterOrganisation,
  reporterTelephone,
  comments,
  commentsIsLoading,
  filesIsLoading,
  informationIsLoading,
  files
}: ServiceRequestDetailTemplateProps) {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: '645px', margin: '0 auto', fontFamily: '"PT Sans", sans-serif', overflow: 'hidden' }} className="email-container">
      <table cellSpacing={0} cellPadding={0} width="100%">
        <tbody>
          <tr>
            <td style={{ padding: '32px 15px 30px', fontSize: 0 }}>
              <img
                src="https://storage.googleapis.com/estate-logs-static-assets/favicons/android-chrome-192x192.png"
                width={40}
                alt="estate logs logo"
              />
            </td>
            <td className='no-print' style={{ textAlign: 'right' }}>
              {informationIsLoading === false &&
                <a
                  className="button-a button-a-primary"
                  href="javascript:window.print()"
                  style={{
                    background: '#E3E3E3',
                    border: '1px solid #E3E3E3',
                    fontSize: '19px',
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    textDecoration: 'none',
                    padding: '12px 25px',
                    color: '#242424',
                    display: 'inline-block',
                    borderRadius: '3px'
                  }}
                >
                  {t('LBL_PRINT')}
                </a>
              }
            </td>
          </tr>
        </tbody>
      </table>
      <table cellSpacing={0} cellPadding={0} width="100%" style={{ margin: 'auto' }}>
        <tbody>
          <tr>
            <td className="p-l-r-15" style={{ padding: '40px 20px 20px', background: '#ffffff', borderTop: '4px solid #2e58f6', textAlign: 'center' }}>
              <h2 style={{ margin: '0', fontSize: '32px', color: '#242424', lineHeight: '36px' }}>
                {t('LBL_SERVICE-REQUEST')}
              </h2>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: '0px 30px 10px',
                background: '#ffffff',
                overflow: 'hidden',
                boxSizing: 'border-box'
              }}>
              {informationIsLoading === true ? (
                <p>{t('LBL_LOADING')}</p>
              ) : (
                <React.Fragment>
                  <table cellSpacing={0} cellPadding={0} width="100%" style={{ tableLayout: 'fixed' }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '16px 0' }}>
                          <table cellSpacing={0} cellPadding={0} width="100%">
                            <tbody>
                              <tr>
                                <td style={{ padding: '16px 0' }}>
                                  <table cellSpacing={0} cellPadding={0} width="100%" style={{ tableLayout: 'fixed' }}>
                                    <tbody>
                                      <tr>
                                        <td className="block p-l-r-0 b-b-0" style={{ padding: '24px', border: '1px solid #E3E3E3', textAlign: 'center' }}>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_CREATED')}
                                          </p>
                                          <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold', color: '#242424', lineHeight: '24px' }}>
                                            {formatDate(createdDatetime)}
                                          </p>
                                        </td>
                                        <td className="block p-l-r-0" style={{ padding: '24px', border: '1px solid #E3E3E3', textAlign: 'center' }}>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_CREATOR')}
                                          </p>
                                          <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold', color: '#242424', lineHeight: '24px' }}>
                                            {creatorName}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table cellSpacing={0} cellPadding={0} width="100%" style={{ tableLayout: 'fixed' }}>
                                    <tbody>
                                      <tr>
                                        <td className="block p-l-r-0 b-b-0" style={{ padding: '24px', border: '1px solid #E3E3E3', textAlign: 'center', borderTopWidth: 0 }}>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_STATUS')}
                                          </p>
                                          <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold', color: '#242424', lineHeight: '24px' }}>
                                            {
                                              statusId === 'unassigned' ? t('LBL_UNASSIGNED') :
                                                statusId === 'pending' ? t('LBL_PENDING') :
                                                  statusId === 'rejected' ? t('LBL_REJECTED') :
                                                    statusId === 'accepted' ? t('LBL_ACCEPTED') :
                                                      statusId === 'cancelled' ? t('LBL_CANCELLED') :
                                                        statusId === 'onhold' ? t('LBL_ON-HOLD') :
                                                          statusId === 'inprogress' ? t('LBL_IN-PROGRESS') :
                                                            statusId === 'completed' ? t('LBL_COMPLETED') :
                                                              '-'
                                            }
                                          </p>
                                        </td>
                                        <td className="block p-l-r-0" style={{ padding: '24px', border: '1px solid #E3E3E3', textAlign: 'center', borderTopWidth: 0 }}>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_REFERENCE')}
                                          </p>
                                          <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold', color: '#242424', lineHeight: '24px' }}>
                                            {referenceCode}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-l-r-15 p-t-15" style={{ padding: '0px 30px 24px', background: '#ffffff' }}>
                          <table cellSpacing={0} cellPadding={0} width="100%">
                            <tbody>
                              <tr>
                                <td style={{ padding: '25px 0px 0 0px' }}>
                                  <strong style={{ display: 'block', marginBottom: '10px', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {t('LBL_ADDRESS')}
                                  </strong>
                                </td>
                                <td style={{ padding: '25px 0px 0 0px', textAlign: 'right' }}>
                                  {address !== '-' &&
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                                      target="_blank"
                                      style={{ color: '#CF4327', textDecoration: 'underline' }} rel="noreferrer"
                                    >
                                      {t('LBL_MAP')}
                                    </a>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ fontSize: '20px', color: '#242424', lineHeight: '24px', padding: '0px 0px 20px 0px' }}>
                                  <p style={{ margin: 0, fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {address}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: '0px 0px 0 0px' }}>
                                  <strong style={{ padding: '25px 0px 0px 0px', borderTop: '1px solid #E3E3E3', display: 'block', marginBottom: '10px', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {t('LBL_LOCATION')}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ fontSize: '20px', color: '#242424', lineHeight: '24px', padding: '0px 0px 20px 0px' }}>
                                  <p style={{ margin: 0, fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {locationName}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={2} style={{ padding: '0px 0px 0 0px' }}>
                                  <strong style={{ padding: '25px 0px 0px 0px', borderTop: '1px solid #E3E3E3', display: 'block', marginBottom: '10px', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {t('LBL_FILES')}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <table role="presentation" cellSpacing={0} cellPadding={0} width="100%">
                                  <tbody>
                                    {filesIsLoading === true ? (
                                      <tr>
                                        <td style={{ padding: '0px 0px 20px' }}>
                                          <p style={{ margin: 0, fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                            {t('LBL_LOADING')}
                                          </p>
                                        </td>
                                      </tr>
                                    ) :
                                      files.length > 0 ?
                                        files.map((file) => {
                                          return (
                                            <tr
                                              style={{
                                                margin: '20px 0'
                                              }}
                                            >
                                              <td style={{
                                                padding: '2px 16px 20px 0',
                                                fontSize: '20px',
                                                color: '#242424',
                                                lineHeight: '24px',
                                                overflow: 'hidden',
                                                textAlign: 'left',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 200
                                              }}>
                                                {file.filename}
                                              </td>
                                              <td style={{ padding: '2px 0 20px 0', fontSize: '20px', color: '#242424', lineHeight: '24px', textAlign: 'right' }}>
                                                {file.url &&
                                                  <a download href={file.url} style={{ color: '#CF4327', textDecoration: 'underline' }} rel="noreferrer">
                                                    {t('LBL_OPEN')}
                                                  </a>
                                                }
                                              </td>
                                            </tr>
                                          )
                                        }) :
                                        <tr>
                                          <td style={{ padding: '0px 0px 20px' }}>
                                            <p style={{ margin: 0, fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                              -
                                            </p>
                                          </td>
                                        </tr>
                                    }
                                  </tbody>
                                </table>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: '0px 0px 0 0px' }}>
                                  <strong style={{ padding: '25px 0px 0px 0px', borderTop: '1px solid #E3E3E3', display: 'block', marginBottom: '10px', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {t('LBL_TASK')}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ fontSize: '20px', color: '#242424', lineHeight: '24px', padding: '0px 0px 20px 0px' }}>
                                  <p style={{ margin: 0, fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {taskName}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{
                                  padding: '25px 0 0 0',
                                  borderTop: '1px solid #E3E3E3',
                                  fontSize: '20px',
                                  color: '#242424',
                                  lineHeight: '24px',
                                  // add padding if there are no <p> tags
                                  paddingBottom: description.includes('<p') ? 0 : 19
                                }}>
                                  <strong style={{ display: 'block', marginBottom: '15px' }}>Description </strong>
                                  <div
                                    style={{
                                      margin: '0',
                                      fontSize: '20px',
                                      color: '#242424',
                                      lineHeight: '24px',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: description }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: '25px 0px 19px 0px', borderTop: '1px solid #E3E3E3', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                  <p style={{ margin: 0 }}>
                                    <strong style={{ display: 'inline-block', marginRight: '10px' }}>
                                      {t('LBL_PETS-PRESENT')}
                                    </strong>
                                    {petsPresent}
                                  </p>
                                  {petsInformation !== '-' &&
                                    <p style={{ margin: '15px 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                      {petsInformation}
                                    </p>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: '25px 0px 19px 0px', borderTop: '1px solid #E3E3E3', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                  <p style={{ margin: 0 }}>
                                    <strong style={{ display: 'inline-block', marginRight: '10px' }}>
                                      {t('LBL_UNATTENDED-ENTRY')}
                                    </strong>
                                    {unattendedEntryPermitted}
                                  </p>
                                  <p style={{ margin: '20px 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                    {t('LBL_VISIT-INSTRUCTIONS')}
                                  </p>
                                  <p style={{ margin: '0 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                    {visitInstructions}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: '25px 0px 19px 0px', borderTop: '1px solid #E3E3E3', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                  <p style={{ margin: 0 }}>
                                    <strong style={{ display: 'inline-block', marginRight: '10px' }}>
                                      {t('LBL_REPORTER')}
                                    </strong>
                                  </p>
                                  <table cellSpacing={0} cellPadding={0} width="100%" style={{ tableLayout: 'fixed' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: '20px 0 15px 0' }}>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_NAME')}
                                          </p>
                                          <p style={{ wordWrap: 'break-word', margin: '0px 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                            {reporterName}
                                          </p>
                                        </td>
                                        <td>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_EMAIL')}
                                          </p>
                                          <p style={{ wordWrap: 'break-word', margin: '0px 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                            {reporterEmail}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_TELEPHONE')}
                                          </p>
                                          <p style={{ wordWrap: 'break-word', margin: '0px 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                            {reporterTelephone}
                                          </p>
                                        </td>
                                        <td>
                                          <p style={{ margin: '0 0 6px', fontSize: '14px', fontWeight: 'bold', color: '#767676', lineHeight: '18px', textTransform: 'uppercase' }}>
                                            {t('LBL_ORGANISATION')}
                                          </p>
                                          <p style={{ wordWrap: 'break-word', margin: '0px 0 10px 0', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                            {reporterOrganisation}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </React.Fragment>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ height: '50px' }} />
      <table cellPadding={0} cellSpacing={0} width="100%" style={{ margin: 'auto', backgroundColor: '#ffffff' }}>
        <tbody>
          <tr>
            <td style={{ padding: '40px 20px 20px', background: '#ffffff', borderTop: '4px solid #2e58f6', textAlign: 'center' }}>
              <h2 style={{ margin: 0, fontSize: '32px', color: '#242424', lineHeight: '36px' }}>
                {t('LBL_LATEST-COMMENTS')}
              </h2>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '0px 60px 50px' }}>
              <table cellPadding={0} cellSpacing={0} width="100%" style={{ backgroundColor: '#ffffff' }}>
                <tbody>
                  {commentsIsLoading === true ? (
                    <p>{t('LBL_LOADING')}</p>
                  ) :
                    comments.length === 0 ? (
                      <p>{t('LBL_NO-COMMENTS')}</p>
                    ) :
                      comments.map((comment) => {
                        return (
                          <React.Fragment key={comment.uuid}>
                            <tr>
                              <td style={{ padding: '35px 0 6px 0' }}>
                                <table cellSpacing={0} cellPadding={0} width="100%">
                                  <tbody>
                                    <tr>
                                      <td style={{ width: '40px', padding: '0 13px 0 0', fontSize: 0 }}>
                                        <Avatars label={comment.creatorName} />
                                      </td>
                                      <td>
                                        <p style={{ margin: 0, fontSize: '18px', color: '#767676', lineHeight: '22px' }}>
                                          {comment.creatorName}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table cellSpacing={0} cellPadding={0} width="100%">
                                  <tbody>
                                    <tr>
                                      <td style={{ padding: '0 0 0 11px' }}>
                                        <p style={{ height: '9px', margin: 0, fontSize: 0 }}>
                                          <img src={triangleBorderImg} width={18} alt="" />
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: '20px 24px 24px', border: '1px solid #E3E3E3' }}>
                                        <p style={{ margin: '0 0 10px', fontSize: '20px', color: '#242424', lineHeight: '24px' }}>
                                          {comment.body}
                                        </p>
                                        <p style={{ margin: 0, fontSize: '16px', color: '#767676', lineHeight: '20px' }}>
                                          {formatDate(comment.createdDatetime, 'lll')}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table cellSpacing={0} cellPadding={0} width="100%">
        <tbody>
          <tr>
            <td style={{
              padding: '40px 60px 70px 60px',
              fontSize: '18px',
              color: '#767676',
              lineHeight: '26px',
              textAlign: 'center'
            }}>
              <b>Estate Logs AB</b><br />
              {t('LBL_CONTACT-MSG')}<br />
              <a
                href="mailto:support@estatelogs.com"
                style={{ color: '#CF4327', textDecoration: 'underline' }}
              >
                support@estatelogs.com
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
