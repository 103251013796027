import React from 'react'
import { store } from '../state/store'
import { Provider } from 'react-redux'

interface IStateProvider {
  children: React.ReactElement
}

export default function StateProvider({ children }: IStateProvider) {
  return (
    <Provider {...{ store }}>
      {children}
    </Provider>
  )
}
