import React from 'react'
import { BrowserRouter } from "react-router-dom";

interface IRouterProvider{
  children: React.ReactElement
}

export default function RouterProvider({ children }: IRouterProvider) {
  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  )
}
