import React, { useCallback } from 'react'
import {
  useGetServiceRequestCommentsQuery,
  useGetServiceRequestInformationQuery,
  useGetServiceRequestFilesQuery
} from '../../state/serviceRequests'
import { useParams } from 'react-router-dom'

const FIVE_MINUTES_IN_MS = 5 * 60000

export default function useServiceRequestDetail() {
  const urlParams = useParams();
  const uuid = urlParams?.uuid || ''

  const {
    data: informationData,
    error: errorData,
    isLoading: informationIsLoading
  } = useGetServiceRequestInformationQuery(uuid, {
    pollingInterval: FIVE_MINUTES_IN_MS
  })

  const {
    data: commentsData,
    error: commentsError,
    isLoading: commentsIsLoading
  } = useGetServiceRequestCommentsQuery(uuid, {
    pollingInterval: FIVE_MINUTES_IN_MS
  })

  const {
    data: filesData,
    error: filesError,
    isLoading: filesIsLoading
  } = useGetServiceRequestFilesQuery(uuid)

  const getAddress = useCallback(() => {
    if (informationData?.data?.address?.address) {
      return `${informationData?.data?.address?.address}`
    } else if (
      informationData?.data?.property?.name &&
      informationData?.data?.property?.region
    ) {
      return `${informationData?.data?.property?.name}, ${informationData?.data?.property?.region}`
    } else {
      return '-'
    }
  }, [informationData])

  const createdDatetime = informationData?.data?.createdDatetime || '-'
  const creatorName = informationData?.data?.creator?.username || '-'
  const referenceCode = informationData?.data?.reference || '-'
  const statusId = informationData?.data?.status || '-'
  const address = getAddress()
  const locationName = informationData?.data?.location?.description || '-'
  const taskName = informationData?.data?.task?.name || '-'
  const description = informationData?.data?.description || '-'
  const petsPresent = (
    informationData?.data?.petsPresent === true ? 'Yes' :
      !informationData?.data?.petsPresent ? 'Unknown' :
        '-'
  )
  const petsInformation = informationData?.data?.petsInformation || '-'
  const unattendedEntryPermitted = (
    informationData?.data?.unattendedEntryPermitted === true ? 'Yes' :
      informationData?.data?.unattendedEntryPermitted === false ? 'No' :
        '-'
  )
  const visitInstructions = informationData?.data?.visitInstructions || '-'

  const reporterName = informationData?.data?.submitter?.name || '-'
  const reporterEmail = informationData?.data?.submitter?.email || '-'
  const reporterOrganisation = informationData?.data?.submitter?.organisation || '-'
  const reporterTelephone = informationData?.data?.submitter?.telephone || '-'

  const comments = commentsData?.data?.map((comment: any) => ({
    uuid: comment?.uuid || '-',
    creatorName: comment?.creator?.username || comment?.creator?.name || '-',
    createdDatetime: comment?.createdDatetime,
    body: comment?.body || '-',
  })) || []

  const files = filesData?.data?.map((file: any) => ({
    filename: file.filename,
    contentType: file.contentType,
    url: file?.variants?.original || file?.url,
    thumbnail: file?.variants?.tiny
  })) || []

  return {
    informationData,
    errorData,
    informationIsLoading,
    commentsData,
    commentsError,
    commentsIsLoading,
    filesData,
    filesIsLoading,
    filesError,
    data: {
      createdDatetime,
      creatorName,
      referenceCode,
      statusId,
      address,
      locationName,
      taskName,
      description,
      petsPresent,
      petsInformation,
      unattendedEntryPermitted,
      visitInstructions,
      reporterName,
      reporterEmail,
      reporterOrganisation,
      reporterTelephone,
      comments,
      files
    }
  }
}
