// http://localhost:3000/issues/0760f0dd-dcac-4796-94c8-63889c29f6f6/

import React from 'react'
import { useParams } from 'react-router-dom'

/*
  Used to redirect legacy share links in the format

  share.estatelogs.com/issue/<uuid/ref>
*/

export default function useIssuePathRedirect() {
  const { uuid } = useParams();

  return {
    "pathname": `/${uuid}/`
  }
}
