import React from 'react'
import useServiceRequestDetail from './useServiceRequestDetail'
import ServiceRequestTemplate from './ServiceRequestDetail.Template'

export default function ServiceRequestDetail() {
  const {
    informationIsLoading,
    commentsIsLoading,
    filesIsLoading,
    data
  } = useServiceRequestDetail()

  return (
    <ServiceRequestTemplate
      {...data}
      {...{
        commentsIsLoading,
        informationIsLoading,
        filesIsLoading
      }}
    />
  )
}
