import React from 'react';
import ServiceRequestDetail from './screens/ServiceRequestDetail'
import NotFound from './screens/NotFound'
import IssuePathRedirect from './screens/IssuePathRedirect'
import { Routes, Route } from "react-router-dom";

function Router() {
  return (
    <Routes>
      <Route
        path='issue/:uuid/'
        element={
          <IssuePathRedirect />
        }
      />
      <Route
        path='/:uuid/'
        element={
          <ServiceRequestDetail />
        }
      />
      <Route
        path="*"
        element={
          <NotFound />
        }
      />
    </Routes>
  );
}

export default Router;
