import React from 'react'
import { getInitials, labelToBackgroundColor } from './Avatars.utils';

interface IAvatars {
  label: string;
}

export default function Avatars({
  label
}: IAvatars) {
  const {
    backgroundColor,
    textColor
  } = labelToBackgroundColor(label);

  return (
    <div
      style={{
        width: 40,
        height: 40,
        backgroundColor: backgroundColor,
        borderRadius: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        color: '#ffffff',
        position: 'relative'
      }}
    >
      <p style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        lineHeight: '40px',
        fontSize: 18,
        zIndex: 2,
        textAlign: 'center',
        color: textColor
      }}>
        {getInitials(label)}
      </p>
    </div>
  )
}
