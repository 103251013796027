import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const prepareHeaders = (headers: Headers) => {
  headers.set(`Content-Type`, `application/json`);

  return headers;
};

const baseUrl = 'https://1.api.estatelogs.com/service-requests/'

export const serviceRequestsApi = createApi({
  reducerPath: 'serviceRequestsApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getServiceRequestInformation: builder.query<any, string>({
      query: (uuid) => uuid + '/',
    }),
    getServiceRequestComments: builder.query<any, string>({
      query: (uuid) => uuid + '/comments/',
    }),
    getServiceRequestFiles: builder.query<any, string>({
      query: (uuid) => '/files/?serviceRequest='+uuid,
    }),
  }),
})

export const {
  useGetServiceRequestCommentsQuery,
  useGetServiceRequestInformationQuery,
  useGetServiceRequestFilesQuery
} = serviceRequestsApi
