import React from 'react'
import useIssuePathRedirect from './useIssuePathRedirect'
import { Navigate } from 'react-router-dom'

export default function IssuePathRedirect() {
  const { pathname } = useIssuePathRedirect()

  return (
    <Navigate
      to={{
        pathname
      }}
    />
  )
}
