import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import resources from '../constants/translations.json';

const BROWSER_LANGUAGE = navigator?.language || (navigator as any)?.userLanguage;

const USER_LANGUAGE = (
  BROWSER_LANGUAGE === 'sv' ? 'sv' :
  BROWSER_LANGUAGE === 'es-SV' ? 'sv' :
  BROWSER_LANGUAGE === 'fi' ? 'fi' :
  BROWSER_LANGUAGE === 'sv-FI' ? 'fi' :
  'en'
)

dayjs.extend(localizedFormat)
dayjs.locale(USER_LANGUAGE)

i18n
  .use(initReactI18next)
  .init({
    resources: resources as any,
    defaultNS: `share`,
    lng: USER_LANGUAGE,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

interface ITranslationsProvider {
  children: React.ReactElement
}

export default function TranslationsProvider({ children }: ITranslationsProvider) {
  return children
}
