const labelToNumber = (label: string) => {
  let sum = 0;

  for (let index = 0; index < label.length; index++) {
    sum += label.charCodeAt(index);
  }

  return sum;
};

export const labelToBackgroundColor = (
  label: string
) => {
  const colors = [
    {
      "backgroundColor": '#206ba9',
      "textColor": '#ffffff',
    },
    {
      "backgroundColor": '#24a150',
      "textColor": '#ffffff',
    },
    {
      "backgroundColor": '#e6641b',
      "textColor": '#ffffff',
    },
    {
      "backgroundColor": '#7a2c9e',
      "textColor": '#ffffff',
    },
    {
      "backgroundColor": '#27384a',
      "textColor": '#ffffff',
    },
    {
      "backgroundColor": '#edba0f',
      "textColor": '#ffffff',
    }
  ]
  const colorIndex = labelToNumber(label) % colors.length;

  return colors[colorIndex];
};

export const getInitials = (label: string) => {
  // return the first two characters if label is a single word, return the first character
  // of the first two words if the label is multiple words.
  // e.g. John => Jo
  //      John Smith => JS
  if (label.includes(` `)) {
    const i = label?.match(/\b(\w)/g);

    if (i && i.length > 0) {
      label = i?.join(``)?.toUpperCase();
      label = label?.substring(0, 2);
    }

    return label;
  } else {
    const i = label?.substring(0, 2);

    return i?.[0]?.toUpperCase() + i?.[1]?.toLowerCase();
  }
};
